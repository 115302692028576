@font-face
  font-family: "VeluxGothic-Black"
  src: url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-Black_WebTT22.eot"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-Black_WebTT.woff") format("woff"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-Black_WebTT.ttf") format("truetype")

@font-face
    font-family: 'VeluxGothic-BlakItalic'
    src: url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-BlackItalic_WebTT.eot"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-BlackItalic_WebTT.woff") format("woff"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-BlackItalic_WebTT.ttf") format("truetype")


@font-face
    font-family: 'VeluxGothic-Bold'
    src: url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-Bold_WebTT.eot"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-Bold_WebTT.woff") format("woff"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-Bold_WebTT.ttf") format("truetype")

@font-face
    font-family: 'VeluxGothic-BoldItalic'
    src: url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-BoldItalic_WebTT.eot"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-BoldItalic_WebTT.woff") format("woff"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-BoldItalic_WebTT.ttf") format("truetype")

@font-face
    font-family: 'VeluxGothic-Light'
    src: url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-Light_WebTT.eot"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-Light_WebTT.woff") format("woff"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-Light_WebTT.ttf") format("truetype")


@font-face
    font-family: 'VeluxGothic-LightItalic'
    src: url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-LightItalic_WebTT.eot"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-LightItalic_WebTT.woff") format("woff"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-LightItalic_WebTT.ttf") format("truetype")


@font-face
    font-family: 'VeluxGothic-Regular'
    src: url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-Regular_WebTT.eot"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-Regular_WebTT.woff") format("woff"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-Regular_WebTT.ttf") format("truetype")


@font-face
    font-family: 'VeluxGothic-RegularItalic'
    src: url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-RegItalic_WebTT.eot"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-RegItalic_WebTT.woff") format("woff"),url("/assets/fonts/Velux-Gothic/webfonts/_VeluxGothic-RegItalic_WebTT.ttf") format("truetype")

//$font-black: "VeluxGothic-Black"
//$font-extraBold: "VeluxGothic-Black"
$font-bold: "VeluxGothic-Bold"
$font-semiBold: "VeluxGothic-Bold"
$font-regular: "VeluxGothic-Regular"
$font-medium: "VeluxGothic-Regular"
$font-light: "VeluxGothic-Light"
$font-thin: "VeluxGothic-Light"

$font-bold-italic: 'VeluxGothic-BoldItalic'
$font-regular-italic: 'VeluxGothic-RegularItalic'
$font-light-italic: 'VeluxGothic-LightItalic'
$font-black-italic: 'VeluxGothic-BlackItalic'
