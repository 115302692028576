@import './_init.scss';

$defaultFontSize: 0.9375rem !important;

body{
  font-size: $defaultFontSize
}

form {
  display: inline;
}


.text-13{
  font-size: 0.8125rem;
}

.text-15{
  font-size: 0.9375rem;
}

.text-17{
  font-size: 1.0625rem;
}


lay-link{
  .lay-content app-text{
    font-size: $defaultFontSize
  }
}

button.is-black-button{
  position: relative;
  background-color: #28231f;
  color: white;
  border-radius: 3px;
  width: 100%;
  height: 100%;
}

.has-text-red{
  color: red;
}

.icons{
  height: 2rem;
  width: 2rem;
}


.icon-color-1{
  color: #737373;
}

.tag{
  border-radius: 6px;
  background-color: rgba(255, 0, 0, 0.08);
  padding: 2px 6px 4px 6px;
  color: #ff0000;
  font-size: 13px;
  line-height: 18px;
}


app-automatic-restart-view{
  
  .is-disabled{
    opacity: 0.5;
    position: absolute;
    background-color: var(--body-background-color);
    z-index: 20;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: not-allowed;
  }

}


app-single-selection-icon:not(.selected).has-border {
  border: 1px solid #d6d6d6;
  border-radius: 50%;
}

app-add-climate-action, app-edit-climate-automation, 
app-schedule-automations-view{

  .is-disabled{
    opacity: 0.5;
    position: absolute;
    background-color: var(--body-background-color);
    z-index: 20;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: not-allowed;
  }
}

app-single-selection-icon{
  height: 20px;
  width: 20px;

  app-icon{
    height: 20px !important;
      width: 20px !important;
  }

  app-selected-icon{
    height: 20px;
    width: 20px;
  }
}

lay-panel-content{
  font-size: $defaultFontSize
}

button.button{
  border-radius: 100px !important;
}