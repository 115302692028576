@charset "utf-8";
/**
 *
 * Set specific variables or override Bulma variable (https://bulma.io/documentation/customize/variables/)
 */
@import "library/sources/sass/utils/_all";

/** Import global variables */
@import "library/sources/sass/_common-variables.sass";
@import "library/sources/sass/variables/_all";


/* Import global class settings */
@import "library/sources/sass/settings";

/* App specific variables */
@import "./variables/typography";
@import "./variables/colors";